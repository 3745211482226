import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalContentComponent } from 'src/app/modal/modal.component';


@Component({

    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    [x: string]: any;
    alertNomClient = false;
    alertVille = false;
    alertAdmin = false;
    alertClientSap = false;
    alertEmailAdmin = false;
    alertEmailAdminConfirm = false;
    alertNbAboHaccp = false;
    alertAboDuree = false;
    alertValidateur = false;
    alertValidateurHe = false;
    alertCodeValidation = false;
    alertTypeFormation = false;
    alertFormule = false;
    alertEmailValidateur = false;
    alertEmailCommercial = false;
    alertNbAboSonde = false;
    showNumeroCommande = false;
    disableValidateButton = false;
    disableReinitButton = true;
    numeroCommande: string;
    formList: any;
    timezoneList: any;
    actifInput = true;
    emailAdmin: any;
    indexEmail: any;
    emailAdminConfirm: any;
    indexEmailConfirm: any;
    emailValid: any;
    indexEmailValid: any;
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    selectedValue: string;
    selectedTimezone: number;
    validateurs = [
        {
            label: 'JDC',
            agences: [
                'ARGENTEUIL',
                'BORDEAUX',
                'CRETEIL',
                'DIJON',
                'GRENOBLE',
                'LILLE',
                'LYON',
                'MARSEILLE',
                'METZ',
                'MULHOUSE',
                'NANTES',
                'ORLEANS',
                'PAU',
                'RENNES',
                'TOULON'
            ]
        },
        {
            label: 'HAXE DIRECT',
            agences: [
                'MONTPELLIER',
                'ROUEN',
                'TOULOUSE'
            ]
        },
        {
            label: 'POPINA',
            agences: ['POPINA']
        },
        {
            label: 'HYGIENE EXPERT',
            agences: ['LES HERBIERS']
        }
    ];

    constructor(
        private orderService: OrderService,
        private toastr: ToastrService,
        private modalService: NgbModal) { }

    ngOnInit() {
        this.orderService.getListTimezone().subscribe(
            (data: any) => {
                this.timezoneList = data.filter(x => x.iana_tz.includes('Europe/'));
                this.selectedTimezone = 331;
            });
        this.orderService.getListForm().subscribe(
            (data: any) => {
                this.formList = data.filter(x => x.label.toLowerCase() === 'essential'
                    || x.label.toLowerCase() === 'basic'
                    || x.label.toLowerCase() === 'premium');
            });
        this.orderService.getListFormation().subscribe(
            (data: any) => {
                this.formationList = data;
            });
        this.selectedValue = 'Sur Site';
    }
    onOptionsSelected(value) {
        if (value === '6') {
            this.actifInput = false;
        } else {
            this.actifInput = true;
        }
    }
    onGeozoneSelected(value) {
        this.orderService.getListTimezone().subscribe(
            (data: any) => {
                this.timezoneList = data.filter(x => x.iana_tz.includes(value));
                if (value === 'Europe/') { this.selectedTimezone = 331; }
            });
    }
    onValidateurChange(orderForm, $event) {
        this.abo = orderForm.value.nb_abo_haccp;
        this.event = $event;
        this.nbAboMin = 3;
        const installationSite = 'installation_site';
        if (orderForm.value.validateur !== 'HYGIENE EXPERT') {
            if (this.abo > this.nbAboMin) {
                this.open(this.event);
            }
        }
        if (orderForm.value.validateur === 'POPINA') {
            orderForm.controls[installationSite].patchValue('non');
        } else {
            orderForm.controls[installationSite].patchValue('');
        }
    }
    open(event) {
        event.srcElement.blur();
        event.preventDefault();
        this.modalService.open(NgbdModalContentComponent);
    }

    /**
     * Soumission du formulaires
     * @param orderForm formulaire
     */
    onFormSubmit(orderForm) {
        const tabOrder: any = {};
        tabOrder.nom_client = orderForm.nom_client;
        tabOrder.ville = orderForm.ville;
        tabOrder.admin = orderForm.admin;
        tabOrder.numero_client_sap = orderForm.numero_client_sap;
        tabOrder.email_admin = orderForm.email_admin.trim();
        tabOrder.formule = orderForm.formule;
        tabOrder.nb_abo_haccp = orderForm.nb_abo_haccp;
        tabOrder.nb_abo_sonde = orderForm.nb_abo_sonde;
        tabOrder.abo_duree = orderForm.abo_duree;
        tabOrder.label = orderForm.formation;
        tabOrder.validateur = orderForm.validateur;
        tabOrder.email_validateur = orderForm.email_validateur.trim();
        tabOrder.email_commercial = orderForm.email_commercial.trim();
        tabOrder.code_validation = orderForm.code_validation;
        tabOrder.appli_timezone_id = orderForm.timezone;
        if (orderForm.installation_site === 'non') {
            tabOrder.installation_site = false;
        } else {
            tabOrder.installation_site = true;
        }
        if (orderForm.full_setup === 'oui') {
            tabOrder.full_setup = true;
        } else {
            tabOrder.full_setup = false;
        }
        // si le formulaire est correctement remplis on envoi les données
        if (this.isformOk(orderForm)) {
            this.disableValidateButton = true;
            this.orderService.postOrder(tabOrder).subscribe(
                (data: any) => {
                    this.resetalerts();
                    // affichage du numero de commande
                    this.numeroCommande = data.numero_commande;
                    this.showNumeroCommande = true;
                    // activation du bouton réinitialisation du formulaire
                    this.disableReinitButton = false;
                },
                (error) => {
                    console.error('erreur', error);
                    this.disableValidateButton = false;
                    try {
                        const stat = 'status';
                        if (error[stat] === 401) {
                            this.alertCodeValidation = true;
                        } else {
                            this.toastr.error('erreur');
                        }
                    } catch (errorbis) {
                        console.error('erreur', errorbis);
                    }
                });
        }
    }

    /**
     * réinitialisation du formulaire de la page
     * @param orderForm formulaire a reinitialiser
     */
    resetOrderForm(orderForm) {
        this.disableValidateButton = false;
        this.disableReinitButton = true;
        this.resetalerts();
        const nomCli = 'nom_client';
        const vil = 'ville';
        const admin = 'admin';
        const numCliSap = 'numero_client_sap';
        const emailAdmin = 'email_admin';
        const emailAdminConfirm = 'email_admin_confirm';
        const nbAboHaccp = 'nb_abo_haccp';
        const nbAboSonde = 'nb_abo_sonde';
        const aboDuree = 'abo_duree';
        const formule = 'formule';
        const formation = 'formation';
        const fullsetup = 'full_setup';
        const geozone = 'geozone';
        const geozoneDefaultValue = 'Europe/';
        orderForm.controls[nomCli].reset();
        orderForm.controls[vil].reset();
        orderForm.controls[admin].reset();
        orderForm.controls[numCliSap].reset();
        orderForm.controls[emailAdmin].reset();
        orderForm.controls[emailAdminConfirm].reset();
        orderForm.controls[formation].reset();
        orderForm.controls[fullsetup].setValue('non');
        orderForm.controls[geozone].setValue(geozoneDefaultValue);
        this.onGeozoneSelected(geozoneDefaultValue);
        if (orderForm.value[formule] === '6') {
            orderForm.controls[nbAboSonde].reset();
            orderForm.controls[aboDuree].reset();
            orderForm.controls[formule].reset();
        } else {
            orderForm.controls[nbAboHaccp].reset();
            orderForm.controls[nbAboSonde].reset();
            orderForm.controls[aboDuree].reset();
            orderForm.controls[formule].reset();
        }
    }
    // vérifie si le formulaire est correct
    isformOk(orderForm) {
        let isok = true;
        this.resetalerts();
        if (orderForm.nom_client === null || orderForm.nom_client === '') {
            this.alertNomClient = true;
            isok = false;
        }
        if (orderForm.ville === null || orderForm.ville === '') {
            this.alertVille = true;
            isok = false;
        }
        if (orderForm.admin === null || orderForm.admin === '') {
            this.alertAdmin = true;
            isok = false;
        }
        if (orderForm.numero_client_sap === null || orderForm.numero_client_sap === '') {
            this.alertClientSap = true;
            isok = false;
        }
        if (orderForm.email_admin === null || orderForm.email_admin === '') {
            this.alertEmailAdmin = true;
            isok = false;
        }
        if (orderForm.email_admin_confirm === null || orderForm.email_admin_confirm === ''
            || orderForm.email_admin !== orderForm.email_admin_confirm) {
            this.alertEmailAdminConfirm = true;
            isok = false;
        }
        if (orderForm.nb_abo_haccp === null || orderForm.nb_abo_haccp === '' || orderForm.nb_abo_haccp === '0') {
            this.alertNbAboHaccp = true;
            isok = false;
        }
        if (orderForm.nb_abo_sonde === null || orderForm.nb_abo_sonde === '' || orderForm.nb_abo_sonde === '0') {
            if (orderForm.nb_abo_haccp === undefined
                || orderForm.nb_abo_haccp === null
                || orderForm.nb_abo_haccp === ''
                || orderForm.nb_abo_haccp === '0') {
                this.alertNbAboSonde = true;
                isok = false;
            } else {
                this.alertNbAboSonde = false;
                isok = true;
            }
        }
        if (orderForm.abo_duree === null || orderForm.abo_duree === '') {
            this.alertAboDuree = true;
            isok = false;
        }
        if (orderForm.validateur === null || orderForm.validateur === '') {
            this.alertValidateur = true;
            isok = false;
        }
        if (orderForm.formation === null || orderForm.formation === '') {
            this.alertTypeFormation = true;
            isok = false;
        }
        if (orderForm.formule === null || orderForm.formule === '') {
            this.alertFormule = true;
            isok = false;
        }
        if (orderForm.code_validation === null || orderForm.code_validation === '') {
            this.alertCodeValidation = true;
            isok = false;
        }
        if (orderForm.email_validateur === null || orderForm.email_validateur === '') {
            this.alertEmailValidateur = true;
            isok = false;
        }

        if (orderForm.email_commercial === null || orderForm.email_commercial === '') {
            this.alertEmailCommercial = true;
            isok = false;
        }
        return isok;
    }
    // reinitialisation des messages d'alertes
    resetalerts() {
        this.alertNomClient = false;
        this.alertVille = false;
        this.alertAdmin = false;
        this.alertEmailAdmin = false;
        this.alertEmailAdminConfirm = false;
        this.alertNbAboHaccp = false;
        this.alertNbAboSonde = false;
        this.alertAboDuree = false;
        this.alertValidateur = false;
        this.alertEmailValidateur = false;
        this.alertEmailCommercial = false;
        this.alertCodeValidation = false;
        this.showNumeroCommande = false;
        this.alertClientSap = false;
        this.alertFormule = false;
        this.alertTypeFormation = false;
    }

    // copie une chaine de caratères dans le presse-papier
    copyMessage(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}
